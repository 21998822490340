<template>
  <div
    class="krpano__wrapper"
    v-if="$route.params.tour"
    @keydown.esc="modal = false"
  >
    <transition-group name="fade-splash" mode="out-in">
      <SplashScreen
        v-if="showingSplash && loading"
        @close="showingSplash = false"
        key="splash"
      />
    </transition-group>
    <!-- <Loader v-if="loading" /> -->
    <Modal
      class="modal"
      :open="modal"
      :content="modalContent"
      @close="modal = false"
    />

    <transition name="fade">
      <div class="gui">
        <Socials />

        <router-link
          :to="`/${this.$locale}`"
          v-if="!isTouchDevice()"
          class="gui__back gui__element gui__element--top-right"
        >
          {{ $t.appBack }}
        </router-link>
        <router-link
          :to="`/${this.$locale}`"
          v-else
          class="gui__back gui__element gui__element--top-right"
        >
          {{ $t.appBackMobile }}
        </router-link>

        <a
          class="gui__logo gui__element gui__element--top-left"
          :href="`/${$locale}`"
          ><img src="/assets/img/logo.png" alt="Client logo"
        /></a>

        <div
          v-if="webVRCapable && !webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="startWebVR">Bekijk in VR</a>
        </div>
        <div
          v-if="webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="exitWebVR">Verlaat VR</a>
        </div>

        <div
          v-if="currentPano && currentTour"
          :class="`gui__title gui__title--${currentTour.color.value} gui__element gui__element--bottom-left`"
        >
          <h2>{{ currentTour.title }} {{ currentTour.location }}</h2>
          <h1>{{ currentPano.title }}</h1>
          <ReadMore
            v-if="currentPano.description"
            :text="currentPano.description"
            :max="210"
          />
          <div class="gui__actions">
            <div class="gui__actions--prev" @click="handlePrevPano()">
              <img src="/assets/img/icons/icon-arrow-2.svg" alt="Arrow icon" />
            </div>
            <div class="gui__actions--next" @click="handleNextPano()">
              <img src="/assets/img/icons/icon-arrow-2.svg" alt="Arrow icon" />
            </div>
          </div>
          <div :class="`bg bg--${currentTour.color.value}`">
            <img src="/assets/img/bg-shape.svg" alt="background shape" />
          </div>
        </div>

        <!-- <a
          href="#!"
          class="side-menu__button gui__element gui__element--top-right"
          @click.prevent="sideMenu = true"
        >
          <div class="side-menu__button-icon">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </a> -->

        <!-- <Dots
          v-if="panos && panos.length > 1"
          class="gui__element gui__element--bottom-center"
          :panos="panos"
          :active="currentPano"
          :loadPano="loadPano"
        /> -->

        <a
          href="https://poppr.be/"
          class="credit gui__element gui__element--bottom-right"
          rel="noopener noreferrer"
          target="_blank"
        >
          Powered by Poppr
        </a>
      </div>
    </transition>
    <div id="krpano"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/Modal.vue";
// import Dots from "@/components/Dots.vue";
import Socials from "@/components/Socials.vue";
// import Loader from "@/components/Loader.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import Hotspot from "@/components/Hotspot.vue";
import ReadMore from "@/components/ReadMore.vue";
import virtualTourApi from "@/api";
import {
  addExitVrHotspot,
  addVrHotspots,
  removeVrHotspots,
} from "@/helpers/vr";
import { isTouchDevice } from "@/helpers";

export default {
  name: "Krpano",
  props: ["info"],
  components: {
    Modal,
    Socials,
    ReadMore,
    // Dots,
    // Loader,
    SplashScreen,
    Hotspot,
  },
  data() {
    return {
      showingTutorial: localStorage.getItem("tutorialShown") ? false : true,
      showingSplash: true,
      loading: true,
      modal: false,
      modalContent: {},
      webVRCapable: false,
      webVRActive: false,
      panos: [],
      panorama: this.$route.params.panorama
        ? this.$route.params.panorama
        : null,
      currentTour: null,
      currentPano: null,
      previousPano: null,
      client: {
        title: process.env.VUE_APP_CLIENT_TITLE,
        url: process.env.VUE_APP_CLIENT_URL,
      },
    };
  },
  mounted() {
    if (this.$route.params.tour) {
      // Tour selected - retrieve panos & init krpano
      this.fetchPanos();
    }
    this.initWindow();
  },
  beforeRouteUpdate(to, from, next) {
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      let previousPano = this.panos.find((p) => p.slug == to.params.panorama);
      if (previousPano && this.currentPano !== previousPano) {
        this.currentPano = previousPano;
        window.krpanoObj.call(
          `loadscene('${previousPano.name}', null, MERGE, BLEND(0.5));`
        );
        this.sendGtmView(
          `${this.currentPano.title} - Virtual tour`,
          `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
        );
      } else {
        history.back();
      }
    }
    next();
  },
  methods: {
    isTouchDevice: isTouchDevice,
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true;
      };
      window.stopLoading = () => {
        this.loading = false;
        this.showingSplash = false;
        // setTimeout(() => (this.loading = false), 500);
      };

      window.popStateDetected = false;
      window.addEventListener("popstate", () => {
        window.popStateDetected = true;
      });

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {
        this.webVRCapable = vrStatus;
      };

      // Init hotspots
      window.loadHotspot = async (spot, pano) => {
        let hotspot = (
          await virtualTourApi.getHotspot(
            this.currentTour.id,
            pano.name,
            spot.name
          )
        ).data;
        if (hotspot.linkedScene || hotspot.linkedPano) {
          let linkedPano = (
            await virtualTourApi.getPano(
              this.currentTour.id,
              hotspot.linkedPano
                ? hotspot.linkedPano.panoName
                : hotspot.linkedScene
            )
          ).data;
          this.loadHotspotStyle(spot, hotspot, linkedPano);
        } else {
          this.loadHotspotStyle(spot, hotspot, null);
        }
      };

      // Handle VR hotspot click
      window.handleVrHotspotClick = (type, title, slug, name) => {
        switch (type) {
          case "navigation":
            this.loadPano(null, { title: title, name: name, slug: slug });
            break;
          case "exit_vr":
            this.exitWebVR();
            break;
          default:
            break;
        }
      };
    },
    async fetchPanos() {
      virtualTourApi.getPanosForTour(this.$route.params.tour).then((res) => {
        this.currentTour = res.data;
        this.$store.state.currentTour = this.currentTour;

        this.panos = res.data.panos;

        if (this.panorama) {
          // Pano selected - init Krpano with certain pano
          let panoToShow = this.panos.find((p) => p.slug == this.panorama);
          this.currentPano = panoToShow;

          if (panoToShow) {
            // Pano is new so let load on init
            this.initTour(panoToShow);
            return;
          }
        }
        // No start pano selected - init Krpano from start
        this.currentPano = res.data.panos[0];
        let panoToShow = this.currentPano;
        this.initTour(panoToShow);
        return;
      });
    },
    initTour(startPano) {
      // Init krpano with the current tour
      var xml = `/assets/vtours/${
        this.currentTour.slug
      }/tour.xml?h=${+new Date()}`;
      let { embedpano } = window;

      embedpano({
        xml: xml,
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        passQueryParameters: true,
        consolelog: process.env.NODE_ENV === "development",
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global");
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag";
            krpanoObj.control.touch = "drag";
          } else {
            krpanoObj.control.mouse = "follow";
            krpanoObj.control.touch = "follow";
          }
        },
        initvars: {
          basePath: "/assets/js",
          partialPath: "/assets/js/partials",
          pluginPath: "/assets/js/plugins",
          assetPath: "/assets/js/assets",
          dev: process.env.NODE_ENV === "development",
        },
      });

      if (startPano) {
        this.loadPano(null, startPano);
      } else if (!this.panorama) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.panorama}`,
        });
      }

      if (!startPano) {
        this.sendGtmView(
          `${this.currentPano.title} - Virtual tour`,
          `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
        );
      }
    },
    loadPano(event, pano) {
      if (event) event.preventDefault();
      if (!pano) return;

      this.currentPano = pano;
      window.krpanoObj.call(
        `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
      );

      this.sendGtmView(
        `${this.currentPano.title} - Virtual tour`,
        `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
      );

      if (this.panorama !== pano.slug) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${pano.slug}`,
        });
      }

      if (this.webVRActive) {
        addExitVrHotspot();
      }
    },
    async loadHotspotStyle(spot, hotspot, panorama) {
      hotspot.linkedPano = panorama;

      let template = `
      <div class="hotspot__wrapper">
        <div id="hotspot-${hotspot.id}"></div>
      </div>
      `;

      // Set basic config
      spot.type = "text";
      spot.renderer = "css3d";
      spot.html = template;
      spot.zoom = false;
      spot.distorted = false;

      // If I want a crosshair?
      // spot.flying = 1;

      if (spot.sprite) {
        // Set the id of the moving hotspot
        spot.sprite.id = `hotspot__locator--${hotspot.id}`;

        // Init hotspot component onto template
        const HotspotCtor = Vue.extend(Hotspot);
        const hotspotElement = new HotspotCtor({
          propsData: {
            webVRActive: this.webVRActive,
            tour: this.currentTour,
            pano: this.currentPano,
            hotspot: hotspot,
          },
          parent: this,
        }).$mount(`#hotspot-${hotspot.id}`);

        // Set click action
        spot.onclick = () => {
          if (isTouchDevice()) {
            document.getElementById(hotspot.id).click();
          }
        };

        // Listen to hotspot
        hotspotElement.$on("navigate", (linkedPano) => {
          this.loadPano(null, linkedPano);
        });
        hotspotElement.$on("info", (content) => {
          this.modalContent = content;
          this.modal = true;
        });
        hotspotElement.$on("image", (data) => {
          this.modalContent = data.content;
          this.modalContent.type = data.type;
          this.modal = true;
        });
      }
    },
    startWebVR() {
      addVrHotspots();
      this.webVRActive = true;
      krpanoObj.call("webvr_onentervr();");
      krpanoObj.call("webvr.enterVR();");
      this.sendGtmEvent("webvr-open", { webvr_pano: this.currentPano.title });
    },
    exitWebVR() {
      removeVrHotspots();
      this.webVRActive = false;
      krpanoObj.call("webvr.exitVR();");
      krpanoObj.call("webvr_onexitvr();");
      krpanoObj.call("lookto(0, 0)");
    },
    handleNextPano() {
      let curPano = this.panos.filter((x) => x.id === this.currentPano.id)[0];
      let indexOfNext = this.panos.indexOf(curPano) + 1;
      if (indexOfNext >= this.panos.length) {
        indexOfNext = 0;
      }
      this.loadPano(null, this.panos[indexOfNext]);
    },
    handlePrevPano() {
      let curPano = this.panos.filter((x) => x.id === this.currentPano.id)[0];
      let indexOfPrev = this.panos.indexOf(curPano) - 1;
      if (indexOfPrev <= -1) {
        indexOfPrev = this.panos.length - 1;
      }
      this.loadPano(null, this.panos[indexOfPrev]);
    },
  },
  metaInfo() {
    return {
      title: `${
        this.currentTour ? this.currentTour.title : "Virtuele tour"
      } - Farys`,
    };
  },
};
</script>

<style lang="scss">
@import "../scss/gui.scss";

.krpano__wrapper {
  background: $color-blue--light;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.hotspot {
  // &__outer {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   background-color: rgba($color-black, 0.5);
  //   width: 40px;
  //   height: 40px;
  //   z-index: -2;
  //   border-radius: 50%;

  //   transition: all $basic-transition-300;
  // }

  // &__wrapper:hover .hotspot__outer {
  //   width: 10px;
  //   height: 10px;
  // }
}
</style>
