<template>
  <router-view />
</template>

<script>
import Vue from "vue";

export default {
  name: "App",
  props: ["translations"],
  created() {
    Vue.prototype.$t = this.translations;
  },
};
</script>
