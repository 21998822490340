<template>
  <div class="home">
    <!-- <Languages class="languages gui__element gui__element--top-right" /> -->

    <div class="home__intro">
      <div class="logo__wrapper">
        <img src="/assets/img/logo.png" alt="Farys logo" class="logo" />
      </div>
      <h1>{{ $t.introTitle }}</h1>
      <p>{{ $t.introText }}</p>
    </div>

    <div class="swiper home__tours">
      <div class="swiper-wrapper">
        <div v-for="(tour, index) in tours" :key="index" class="swiper-slide">
          <div :class="`tour tour__${index}`" @click="handleClick(tour)">
            <h2>{{ tour.title }}</h2>
            <p>{{ tour.location }}</p>
            <router-link
              :to="`/${$locale}/virtual-tour/${tour.slug}`"
              v-if="tour.location !== 'Oostende'"
            >
              <span>{{ $t.introLink }}</span>
              <img src="/assets/img/icons/icon-arrow.svg" alt="Arrow icon" />
            </router-link>
            <a
              v-else
              href="https://www.openbedrijvendagvirtueel.be/virtual-tour/farys/wpc-oostende"
              rel="noopener noreferrer"
            >
              <span>{{ $t.introLink }}</span>
              <img src="/assets/img/icons/icon-arrow.svg" alt="Arrow icon"
            /></a>
          </div>
          <div :class="`bg bg--${tour.color.value}`">
            <img src="/assets/img/bg-shape.svg" alt="background shape" />
          </div>
        </div>
      </div>
    </div>

    <!-- Video -->
    <div class="home__video-bg">
      <div class="home__video--overlay"></div>
      <video
        class="home__video"
        autoplay
        playsinline
        muted
        loop
        controlsList="nodownload"
        id="bg-video"
        poster="/assets/img/video-poster.png"
      >
        <source
          src="https://player.vimeo.com/progressive_redirect/playback/686639994/rendition/1080p?loc=external&signature=6afaed6cf4779fcef61894d4a70c211297288bbda7f8a4dba99e2416e045ccad"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</template>

<script>
import virtualTourApi from "@/api"
// import Languages from "@/components/Languages";
import Swiper from "swiper"

export default {
  name: "Home",
  props: ["page"],
  components: {
    // Languages,
  },
  data() {
    return {
      tours: [],
    }
  },
  mounted() {
    virtualTourApi.getAllTours().then((res) => {
      this.tours = res.data
      new Swiper(".swiper", {
        spaceBetween: 0,
        slidesPerView: "auto",
      })
    })
  },
  methods: {
    handleClick(tour) {
      if (tour.location !== "Oostende") {
        this.$router.push(`/${this.$locale}/virtual-tour/${tour.slug}`)
      } else {
        window.location.href =
          "https://www.openbedrijvendagvirtueel.be/virtual-tour/farys/wpc-oostende"
      }
    },
  },
}
</script>
<style lang="scss">
@import "../scss/lib/swiper-bundle.min.css";

.home {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 10rem;

  @include for-tablet-landscape-up {
    // height: 100%;
    overflow-y: auto;
    grid-gap: 15rem;
    display: grid;
    grid-template: 3fr 2fr / 1fr;
  }

  * {
    color: $color-text--inverse;
  }

  .logo__wrapper {
    margin-top: 2rem;
    max-width: 30rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    max-width: 90%;
    margin: 0 auto;

    @include for-tablet-landscape-up {
      justify-content: flex-end;
      max-width: 70%;
    }

    h1 {
      margin: 5.6rem 0 2.4rem 0;
    }
  }

  &__tours {
    margin: 0;
    padding: 0;

    width: 100%;
    overflow: hidden;

    .swiper-slide {
      width: 90%;
      margin-right: 8rem;
      padding-right: 5rem;
      min-width: 250px;
      height: 100%;

      .tour > * {
        max-width: 80%;
        word-wrap: anywhere;
      }

      @include for-tablet-landscape-up {
        margin: unset;
        padding: unset;
        width: 25%;
        min-width: 400px;

        .tour > * {
          max-width: 70%;
          word-wrap: anywhere;
        }
      }

      &:hover {
        cursor: pointer;

        a {
          span {
            border-bottom: 2px solid transparent;
          }

          img {
            left: 0.8rem;
          }
        }

        .bg {
          &--blue-dark {
            // #002A57
            filter: invert(12%) sepia(33%) saturate(5211%) hue-rotate(195deg)
              brightness(80%) contrast(105%);
          }

          &--blue-light {
            // #0069B4
            filter: invert(26%) sepia(83%) saturate(1824%) hue-rotate(186deg)
              brightness(85%) contrast(101%);
          }

          &--green {
            // #AECD60
            filter: invert(92%) sepia(9%) saturate(2116%) hue-rotate(22deg)
              brightness(75%) contrast(95%);
          }
        }
      }
    }

    .tour {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: 35rem;

      @include for-tablet-landscape-up {
        min-height: unset;
      }

      h2 {
        margin-bottom: 0.8rem;
      }

      a {
        margin: 2.4rem 0 6.4rem 0;
        text-decoration: none;

        display: flex;
        align-items: flex-start;

        span {
          border-bottom: 2px solid $color-white;
          padding-bottom: 0.2rem;
          font-weight: 700;
          transition: all $basic-transition-300;
        }

        img {
          position: relative;
          max-width: 2rem !important;
          margin-left: 1.6rem;
          transition: all $basic-transition-300;
          left: 0;
        }
      }
    }

    .bg {
      position: absolute;
      top: 0;
      z-index: -1;
      right: 0;
      left: -25rem;

      transition: all $basic-transition-300;
      pointer-events: none;

      @include for-tablet-landscape-up {
        right: -10rem;
        left: -25rem;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &--blue-dark {
        // #002A57
        filter: invert(12%) sepia(33%) saturate(5211%) hue-rotate(195deg)
          brightness(92%) contrast(105%);
      }

      &--blue-light {
        // #0069B4
        filter: invert(26%) sepia(83%) saturate(1824%) hue-rotate(186deg)
          brightness(90%) contrast(101%);
      }

      &--green {
        // #AECD60
        filter: invert(92%) sepia(9%) saturate(2116%) hue-rotate(22deg)
          brightness(84%) contrast(95%);
      }
    }
  }

  &__video {
    z-index: -3;
    object-fit: cover;
    filter: brightness(110%);

    &-bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      * {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    &--overlay {
      z-index: -2;
      height: 100%;
      width: 100%;
      background: rgba(#9be1fa, 0.4);
    }
  }
}
</style>
