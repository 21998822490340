<template>
  <div
    v-if="hotspot.type"
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano ? hotspot.linkedPano.title : ''"
    :data-slug="hotspot.linkedPano ? hotspot.linkedPano.slug : ''"
    :data-name="hotspot.linkedPano ? hotspot.linkedPano.name : ''"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    @click="handleClick"
    v-click-outside="handleClickOutside"
  >
    <div :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`">
      <img
        v-if="hotspot.type.value === 'info'"
        class="icon--primary"
        :src="getIcon()"
        alt="hotspot icon"
      />
      <h3
        v-else-if="hotspot.type.value === 'navigation'"
        class="hotspot__title"
      >
        {{ hotspot.linkedPano.title }}
      </h3>
      <img
        v-else-if="hotspot.type.value === 'image'"
        class="icon--primary"
        src="/assets/img/icons/icon-info-gallery.svg"
        alt="hotspot icon"
      />
      <img
        v-else
        class="icon--primary"
        :src="`/assets/img/icons/icon-${hotspot.type.value}.svg`"
        alt="hotspot icon"
      />

      <div
        :class="`hotspot__content ${`hotspot__content--${hotspot.type.value}`}`"
        v-if="hotspot.type.value == 'small-info' && hotspot.modal.smallContent"
      >
        <p
          v-if="
            hotspot.type.value == 'small-info' && hotspot.modal.smallContent
          "
          v-html="hotspot.modal.smallContent.substring(0, 500)"
        ></p>
        <div
          v-if="hotspot.type.value !== 'small-info'"
          class="hotspot__content__bg"
        ></div>
      </div>

      <div
        class="hotspot__top"
        v-if="hotspot.type.value === 'navigation'"
      ></div>
      <div
        class="hotspot__line"
        v-if="hotspot.type.value === 'navigation'"
      ></div>
    </div>
    <div class="hotspot__outer" v-if="hotspot.type.value === 'navigation'">
      <div class="inner"></div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import { addSingleVrHotspot } from "@/helpers/vr";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    webVRActive: Boolean,
    tour: Object,
    pano: Object,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    let hotspotDOM = document.getElementById(this.hotspot.id);
    if (this.webVRActive && hotspotDOM) {
      addSingleVrHotspot(hotspotDOM);
    }
  },
  methods: {
    getIcon() {
      if (this.hotspot.modal.hasVideo)
        return "/assets/img/icons/icon-info-video.svg";
      if (this.hotspot.modal.images)
        return "/assets/img/icons/icon-info-gallery.svg";
      return "/assets/img/icons/icon-info.svg";
    },
    handleClickOutside(event) {
      this.clickCount = 0;
      if (
        document.getElementById(this.hotspot.id) &&
        document
          .getElementById(this.hotspot.id)
          .classList.contains("hotspot__small-info")
      ) {
        document.getElementById(this.hotspot.id).classList.remove("touched");
      }
    },
    updateClasses() {
      document.querySelectorAll(".hotspot__small-info").forEach((el) => {
        el.classList.remove("touched");
      });
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (
        this.isTouchDevice &&
        this.clickCount < 2 &&
        this.hotspot.type.value !== "info" &&
        this.hotspot.type.value !== "small-info"
      )
        return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        case "image":
          this.$emit("info", {
            content: this.hotspot.modal,
            type: this.hotspot.type.value,
          });
          data.modal_on = this.pano.title;
          data.modal_type = "Image modal";
          break;
        case "small-info":
          const smallInfo = document.getElementById(this.hotspot.id);
          if (!smallInfo.classList.contains("touched")) {
            document.getElementById(this.hotspot.id).classList.add("touched");
          }
          break;
        default:
          break;
      }

      // Fire GTM event for click
      if (data) {
        this.sendGtmEvent(
          "click-hotspot",
          data,
          this.tour.title,
          this.pano.title
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hotspot-dimension: 44px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50px;

  display: grid;
  place-content: center;

  transition: all $basic-transition-200;

  &:not(.hotspot__navigation) {
    background: $color-blue--light;
    box-shadow: 0 10px 20px 8px rgba(0, 0, 0, 0.15);
  }

  &__info:hover,
  &__image:hover {
    background: darken($color-blue--light, 5%);
    box-shadow: 0 10px 32px 10px rgba(0, 0, 0, 0.15);
  }

  &__inner {
    &__info,
    &__small-info,
    &__image {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;

        filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(44deg)
          brightness(107%) contrast(101%) !important;

        transform: scale(0.8);
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 50px;
    border-radius: 50px;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;

    span {
      opacity: 0;
      transition: all $basic-transition-1000;
    }

    &__bg {
      background: $color-blue--dark;
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-transition-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      padding: 2.4rem;
      flex-direction: column;
      // min-height: 200px;
      height: auto;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      background: $color-white;
      opacity: 0;
      transition: all $basic-transition-200;

      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 30rem;
      text-align: left;

      span {
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-transition-500;
      }

      p {
        font-weight: normal;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-transition-500;
        white-space: break-spaces;
      }
    }

    * {
      color: $color-text;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__navigation:hover {
    .hotspot__top {
      top: 0px;
    }

    .hotspot__title {
      top: -25px;
      opacity: 1;
    }
  }

  &__title {
    color: $color-black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
    opacity: 0;

    background: $color-white;
    border-radius: 3px;
    padding: 0.2rem 1.6rem;

    @include fontSize(1.5rem, 1.5, 1.5rem);
    font-weight: 700;

    transition: all $basic-transition-300;
  }

  &__top {
    background: $color-white;
    border: 9px solid $color-blue--light;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
    z-index: 1;

    transition: all $basic-transition-200;
  }

  &__line {
    background: $color-blue--light;
    width: 3px;
    height: 40px;
    position: absolute;
    top: unset;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__outer {
    background: $color-white;
    border-radius: 50%;
    width: 30px;
    height: 15px;
    position: absolute;
    top: unset;
    bottom: -10px;
    z-index: -3;
    left: 50%;
    transform: translateX(-50%);

    & .inner {
      background: $color-blue--light;
      position: absolute;
      top: unset;
      bottom: 0px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -65%);
      border-radius: 50%;
      width: 12px;
      height: 6px;
      z-index: -2;
    }
  }

  &:hover,
  &.touched {
    .hotspot__top {
      top: 0px;
    }

    .hotspot__title {
      top: -23px;
      opacity: 1;
    }

    &.hotspot__small-info {
      width: calc(#{$hotspot-dimension} + 6px);
      height: calc(#{$hotspot-dimension} + 6px);
      background: $color-white;

      img {
        opacity: 1;
        filter: none !important;
      }

      .hotspot__content {
        opacity: 1;
        pointer-events: all;

        span,
        p {
          opacity: 1;
        }

        &__bg {
          width: 100%;
        }
      }
    }
  }
}
</style>
