<template>
  <div>
    <div
      v-for="(image, index) in content.images"
      :key="index"
      :id="`image${index}`"
      class="swiper-slide img__wrapper swiper-no-swiping invisible"
      @click="tinyIndex = index"
    >
      <img :src="image" alt="Modal image" />
    </div>
    <Tinybox v-model="tinyIndex" :images="content.images" no-thumbs loop />
  </div>
</template>

<script>
import Tinybox from "vue-tinybox";

export default {
  name: "ImageModal",
  components: {
    Tinybox,
  },
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  data() {
    return {
      tinyIndex: null,
      activeIndex: 0,
    };
  },
  updated() {
    document
      .querySelectorAll(".tinybox__content__control--close")[0]
      .addEventListener("click", () => {
        this.$emit("close");
      });
    document
      .querySelectorAll(".tinybox__content")[0]
      .addEventListener("click", () => {
        this.$emit("close");
      });
  },
};
</script>

<style></style>
